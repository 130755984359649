/* Section 3 */

  .section3 h1 {
    font-weight: bold;
    text-align: center;
    font-size: 35px;
    color: rgb(56, 56, 56);
    margin-bottom: 5%;
  }

  .sous_section3 {
    display: flex;
    justify-content: center; /* Centrage vertical */
    margin-bottom: 2%;
    margin-left: 2%;
  }
  
  .section3_liste {
    background-color: rgb(192, 199, 199, 0.4);
    width: 92%;
    height: 96%;
    display: flex; /* Utilisation de flexbox */
    flex-direction: column; /* Empile les éléments verticalement */
    align-items: center; /* Centrer verticalement */
    border-radius: 4px;
    transition: transform 0.3s ease-in-out; /* Ajout de la transition */

  }

  .section3_liste:hover {
    background-color: rgb(192, 199, 199, 0.9);
    transform: scale(1.2);
  }

  .sous_section3 img {
    height: 40%;
    width: auto;
    max-width: 80%;
  }

  
  .sous_section3 h2 {
    font-weight: bold;
    font-size: 35px;
    color: rgb(56, 56, 56);
    text-align: center;
    margin-top: 10%; /* Resetting the default top margin */
    margin-bottom: 10%; /* Resetting the default bottom margin */
  }

  .sous_section3 h3 {
    color: rgb(56, 56, 56);
    font-weight:normal;
    text-align: center;
    margin-top: -7%; /* Resetting the default top margin */
    margin-bottom: 10%; /* Resetting the default bottom margin */
  }

  .produit{
    width: 80%;
  }

  .sous_section3 p {
    font-weight: bold;
    font-size: 18px;
    color: rgb(56, 56, 56);
    text-align: center;
    line-height: 1.3;
    margin-top: 15%;
    width: 80%;
  }

  .section3 .button_openPopUp{
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    list-style: none;
  }
  
  .button_openPopUp:hover{
    transform: scale(1.1);
    color: black;
  }
  

/* Utilisation des mêmes breakpoints que ceux d'Ant Design */
@media screen and ((max-width: 850px) or (orientation: portrait)) {
  .section3 {
    padding-top:18%;
    padding-bottom: 0;
    margin-left: 1%;
  }

  .section3 h1 {
    font-size: 5vw;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 10%;
  }
  
  .section3 h2 {
    font-size: 5vw;
    text-align: center;
  }


  .produit{
    width: 70%;
    margin-bottom: 10%;
    margin-left: 15%;

  }

  .section3 p {
    font-size: 3.2vw;
    margin-top: 2%;
    line-height: 1.2; /* Ajustez la valeur selon vos préférences */
    text-align: center;
    width: 90%;

  }

  .section3 .button_openPopUp{
    margin-top : 5%;
    margin-bottom : 30%;
    font-size: 4.5vw;

  }
}
