/* AuthPopup.css */

.resetPassword-popup {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  z-index: 10;
}

.resetPassword-popup h1{
  font-size: 26px;  
}

.resetPassword-popup h2{
  font-size: 26px;  
  margin-top: 10%;
}

::placeholder {
  color: white;;
}

.popup-content {
  border: none;
  display: flex;
  background-color: rgb(0, 67, 67);
  border-radius: 4px;
  color: white;
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  height: fit-content;
}


input {
  border: none;
  border-bottom: 1px solid;
  width: 90%;
  background:none;
  color : white;
  font-size: 20px;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-left: 2%;
  padding-bottom: 2%;
}

input:focus {
  outline: none; /* Supprime la bordure de focus */
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 18px;
}

.button_signUp {
  text-decoration: none;
  color: rgb(56, 56, 56);
  font-size: 20px;
  border: none; /* Remove border */
  cursor: pointer; /* Add a pointer cursor on hover (optional) */
  background-color: white;
  padding: 15px 20px; /* Adjust the values as needed */
  font-weight: bold;
  border-radius: 4px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)
}

.button_signUp:hover{
  transform: scale(1.1);
  color: rgb(56, 56, 56);
}

.email-error2 {
  color: rgb(209, 27, 27); /* Définir la couleur du texte en rouge */
}

.image-slider {
  background-color: rgb(192, 199, 199);
  padding:10%;
  border-radius: 0 4px 4px 0 ;
}


 a {
  font-size: 50px;
  margin-right: 4px;

}



/* Utilisation des mêmes breakpoints que ceux d'Ant Design */
@media screen and (max-width: 576px) {

  .popup-content {
    min-height: fit-content;
  }

  input {
    font-size: 4vw;
  }

  a {
    font-size: 8vw;
  }
  
  .popup-content h1 {
    font-size: 4vw;
    margin-top: 15%;
  }

  .popup-content h2 {
    font-size: 4vw;
    margin-top: 12%;
  }

  .button_signUp {
    margin-top: 2%;
    font-size: 4vw;
    padding: 10px 15px; /* Adjust the values as needed */
  }

  .close-button {
    top: 10px;
    right: 10px;
    font-size: 15px;
  }


}




