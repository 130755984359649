/* Section 2 */
  .section2 h1 {
    font-weight: bold;
    text-align: center;
    font-size: 35px;
    color: rgb(56, 56, 56);
    margin-bottom: 2%;
}
 
  .section2 {
    padding-left: 3%;
    padding-right: 3%;
    list-style: none;
  }
  
  .section2_liste {
    width: 80%;
    margin-left: 10%;
  }
  
  
  .section2 h2 {
    font-weight: bold;
    text-align: left;
    font-size: 40px;
    color: rgb(56, 56, 56);
  }
  
  .section2 p {
    margin-top: 15%;
    font-size: 18px;
    font-weight:bold;
    text-align:justify;
    line-height: 1.5; /* Ajustez la valeur selon vos préférences */
    color: rgb(56, 56, 56);
  }

  .section2 .button_openPopUp{
    margin-top : 20%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    list-style: none;
  }
  
  .button_openPopUp:hover{
    transform: scale(1.1);
    color: black;
  }
  


/* Utilisation des mêmes breakpoints que ceux d'Ant Design */
@media screen and ((max-width: 850px) or (orientation: portrait)) {
  .section2 {
    padding-top:18%;
    padding-bottom: 0;
  }

  .section2 h1 {
    font-size: 5vw;
    text-align: center;
    margin-top: 3%;
    margin-bottom: 2%;
  }
  
  .section2 p {
    font-size: 3.5vw;
    margin-top: 2%;
    line-height: 1.2; /* Ajustez la valeur selon vos préférences */
  }
  
  .section2 h2 {
    font-size: 5vw;
    text-align: center;
  }

  .section2 .button_openPopUp{
    margin-top : 17%;
  }
}


