header {
  background-color: rgb(237, 247, 247, 0.95);
  z-index: 1000;
  height: 9vh;
  min-height: 60px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1vh 1vh 1vh 5vh;
  font-size: 1.5vw;
}

.logo{
  cursor: pointer;
}

.header-menu1 ul {
  white-space: nowrap;
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
}

.header-menu li {
  margin-right: 2%;
  cursor: pointer;
}

.button_openPopUp {
  background-color: #004343; /* rgb(56, 56, 56, 0.9); */
  color: white;
  padding: 15px 20px; /* Adjust the values as needed */
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  border: none; /* Remove border */
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.button_openPopUp:hover{
  transform: scale(1.1);
  color: black;
}



@media screen and ((max-width: 850px) or (orientation: portrait)) {
  .ant-menu {
    height: 100%;
    width: fit-content;
    background-color: transparent;
  }

  .ant-menu-inline .ant-menu-submenu-title {
    overflow: visible;
  }

  .ant-menu-title-content{
    text-align: right;
    height: fit-content;
  }

  .ant-menu-submenu {
    height: 100%;
    padding-top: 2vw;
  }
  
  .ant-menu-submenu-open {
    height: fit-content;
    background-color: #e5e7e7;
  }

  .menu-item-text-3 {
    font-weight: bold;
    color: #004343;
  }

  .logo{
    cursor: pointer;
    max-height: 80%;
  }
}

@media screen and ((min-width: 800px) and (orientation: portrait)) {
  

  .menu-item-text-2 {
    font-size: 3vw;
    color: #004343;
  }

  .menu-item-text-3 {
    font-size: 3.5vw;
    color: #004343;
    font-weight: bold;
  }

  .ant-menu-item, .ant-menu-item-only-child {
    padding: 3.5vw;
  }
}

