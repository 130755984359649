.section1 {
  color: rgb(56, 56, 56);
  text-align: justify;
  padding-top:2%
}

.section1 h1 {
  font-size: 4vw;
  margin-bottom: 3%;
  text-align: left; 
}
  
.section1 p {
  line-height: 1.5; /* Ajustez la valeur selon vos préférences */
  font-size: 22px;
  margin-bottom: 6%;
}
  
.section1 h2 {
  font-size: 35px;
  margin-bottom: 10%;
}

.section1 ul {
  padding: 0
}

.phone {
  width: 100%;
  height: auto;
}


.section1 .button_openPopUp{
  font-size: 18px;
  font-weight: bold;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
  width: fit-content;
  margin: 0 auto; /* Centre l'élément horizontalement */
}

.button_openPopUp {
  margin: 0% 0%;
}

.button_openPopUp:hover{
  transform: scale(1.1);
  color: black;
}

/* styles.css */

/* Utilisation des mêmes breakpoints que ceux d'Ant Design */
@media screen and ((max-width: 850px) or (orientation: portrait)) {
  .section1 {
    padding-top:10%;
    padding-bottom: 0;
  }

  .section1 h1 {
    font-size: 5.5vw;
    text-align: center;
  }
  
  .section1 p {
    font-size: 4vw;
  }
  
  .section1 h2 {
    font-size: 5vw;
    text-align: center;
  }
}


/* Utilisation des mêmes breakpoints que ceux d'Ant Design */
@media screen and ((min-width: 750px) and (orientation: portrait)) {
  .section1 {
    padding-top:1%;
  }
  
  .section1 p {
    font-size: 3vw;
    margin-bottom: 0;

  }
  
  .section1 h2 {
    font-size: 3vw;
    margin-bottom: 5%;
  }
}